import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  public errorMessage: string = '';

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  // TODO: Change Type!!
  onSubmit() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.authService.login(this.form.value.username, this.form.value.password).then((result) => {
        this.router.navigate(['/questions']);
      }).catch((err) => {
        console.log(err.message);
        this.errorMessage = err.message;
        setTimeout(() => { this.errorMessage = "" }, 4000)
      })
    }
  }

}
