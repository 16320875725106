import { AnswerModal } from "./answer.modal";

export class QuestionModal {
    constructor(
        public id: number,
        public type: string,
        public question: string,
        public answer_1: AnswerModal,
        public answer_2: AnswerModal,
        public answer_3: AnswerModal,
        public answer_4: AnswerModal,
        public correctAnswers: string,
        public selectedAnswers: string,
        public isAnswerCorrect: boolean
        ) { }
}