import { Component, OnInit } from '@angular/core';
import { QuestionService } from './service/question.service';
import { QuestionModal } from 'src/app/shared/question.modal';
import { ThrowStmt } from '@angular/compiler';
import { AnswerModal } from '../shared/answer.modal';
import { Listener } from 'selenium-webdriver';


@Component({
  selector: 'questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit 
{
  title = "Quiz";

  public totalNumberOfQuestions : number = 0;
  public questions: any = [];
  public currentQuestion: QuestionModal;
  public correctAnswers: string = "";
  public result : string = "";
  public showResult : boolean = false;

  constructor(private questionService: QuestionService) { 
    this.getData()
    this.currentQuestion = new QuestionModal(1,"CORE","Default Question", new AnswerModal("a1",false),new AnswerModal("a2",false),new AnswerModal("a3",false),new AnswerModal("a4",false),"1","",false);
  }

  getData() {
    this.questionService.getAllQuestions().subscribe(result => {
      this.questions = result;
      this.totalNumberOfQuestions = this.questions.length;
      this.currentQuestion = this.questions[0];
    });
  }

  getQuestion(id : number) {
    return this.currentQuestion = this.questions[id];
  }

  onChange(answerNumber : string) {
    if (answerNumber === "answer_1") {
      this.currentQuestion.answer_1.selected = !this.currentQuestion.answer_1.selected
    }
    else if (answerNumber === "answer_2") {
      this.currentQuestion.answer_2.selected = !this.currentQuestion.answer_2.selected
    }
    else if (answerNumber === "answer_3") {
      this.currentQuestion.answer_3.selected = !this.currentQuestion.answer_3.selected
    }
    else if (answerNumber === "answer_4") {
      this.currentQuestion.answer_4.selected = !this.currentQuestion.answer_4.selected
    }
  }

  next() {
    this.correctAnswers = "";
    if (this.currentQuestion.id < this.totalNumberOfQuestions)
    {
      this.getQuestion(this.currentQuestion.id);
    }
  }

  previous(){
    this.correctAnswers = "";
    if ( (this.currentQuestion.id - 1) > 0)
    {
      this.getQuestion(this.currentQuestion.id - 2);
    }
  }

  showInfo() {
    if (this.correctAnswers === "") {
      this.correctAnswers = "Correct Anwer: " + this.currentQuestion.correctAnswers.replace(/answer_/g,"");
    }
    else {
      this.correctAnswers = "";
    }
    
  }

  getSelectedAnswers(question : QuestionModal) : string[]{
    var selectedAnswers : string[] = [];

    if (question.answer_1.selected) {
      selectedAnswers.push("answer_1");
    }

    if (question.answer_2.selected) {
      selectedAnswers.push("answer_2");
    }

    if (question.answer_3.selected) {
      selectedAnswers.push("answer_3");
    }

    if (question.answer_4.selected) {
      selectedAnswers.push("answer_4");
    }
  
    return selectedAnswers;
  }

  quizResult() {
    this.showResult = true;

    var numberCorrect = 0;
    this.questions.forEach((element: QuestionModal) => {
      var selectedAnswers : string = this.getSelectedAnswers(element).toString();
      element.selectedAnswers = selectedAnswers;
      if (element.correctAnswers === selectedAnswers) {
        numberCorrect++;
        element.isAnswerCorrect = true;
       }
      else {
        element.isAnswerCorrect = false;
      }
    });

    this.result = (100 / this.totalNumberOfQuestions * numberCorrect).toFixed(2) + "%";
  }

  showQuiz() {
    this.showResult = false;
  }

  ngOnInit(): void {
  }

}
