import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { QuestionsComponent } from './questions/questions.component';
import { AuthGuard } from './login/auth.guard';
import { NotAuthGaurd } from './login/notAuth.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent, canActivate: [NotAuthGaurd]},
  { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard]},
  { path: '**', component: LoginComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }