<div class="toolbar" role="banner">
    <img
        width="40"
        alt="SAP Commerce Cloud Logo"
        src="/assets/images/48x48_sap_commerce_cloud_logo.svg"/>
    <div class="toolbar-title">{{ title }}</div>
    
    <div class="logoutLink" (click)="signOut()">
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon" style="transform: rotate(270deg);">save_alt</mat-icon> Logout
    </div>
</div>