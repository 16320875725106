<!-- Toolbar -->
<app-toolbar></app-toolbar>

<div class="main">
    <div class="box">
        <div *ngIf="!showResult; then quizBlock else resultBlock" class="content" role="main"></div>

        <ng-template #quizBlock>
            <h2>Question {{ currentQuestion.id }} of {{ totalNumberOfQuestions }}</h2>
            <section class="terminal-container terminal-fixed-top">
                <header class="terminal">
                    <span class="button red"></span>
                    <span class="button yellow"></span>
                    <span class="button green"></span>
                    <span class="space">{{ correctAnswers }}</span>
                    <div class="infoLink" (click)="showInfo()">
                        <mat-icon>info</mat-icon>
                    </div>
                </header>

                <div class="terminal-home">
                    <h3>Question {{ currentQuestion.id }}: {{ currentQuestion.question }}</h3>
                    <p class="console">
                        <input type="checkbox" id="{{ currentQuestion.id }}_a1" name="{{ currentQuestion.id }}_a1" (change)="onChange('answer_1')" [checked]="currentQuestion.answer_1.selected"/>
                        <span class="answer">{{ currentQuestion.answer_1.value }}</span>
                    </p>
                    <p class="console">
                        <input type="checkbox" id="{{ currentQuestion.id }}:a2" name="{{ currentQuestion.id }}_a2" (change)="onChange('answer_2')" [checked]="currentQuestion.answer_2.selected"/>
                        <span class="answer">{{ currentQuestion.answer_2.value }}</span>
                    </p>
                    <p class="console">
                        <input type="checkbox" id="{{ currentQuestion.id }}_a3" name="{{ currentQuestion.id }}_a3" (change)="onChange('answer_3')" [checked]="currentQuestion.answer_3.selected"/>
                        <span class="answer">{{ currentQuestion.answer_3.value }}</span>
                    </p>
                    <p class="console">
                        <input type="checkbox" id="{{ currentQuestion.id }}_a4" name="{{ currentQuestion.id }}_a4" (change)="onChange('answer_4')" [checked]="currentQuestion.answer_4.selected"/>
                        <span class="answer">{{ currentQuestion.answer_4.value }}</span>
                    </p>
                    <div class="questionButtons">
                        <button type="button" mat-raised-button (click)="previous()">Previous</button>
                        <span class="space">
                            <button type="button" mat-raised-button (click)="quizResult()">Result</button>
                        </span>
                        <button type="button" mat-raised-button (click)="next()">Next</button>
                    </div>
                </div>
            </section>
        </ng-template>
        
        <ng-template #resultBlock>
            <h2>Result: {{ result }} | <a href="javascript:void(0)" (click)="showQuiz()">Back to Quiz</a></h2>
            <section class="terminal-container terminal-fixed-top">
                <header class="terminal">
                    <span class="button red"></span>
                    <span class="button yellow"></span>
                    <span class="button green"></span>
                    <span class="space"></span>
                </header>
                <div class="terminal-home">
                    <div *ngFor="let question of questions">
                        <span *ngIf="question.isAnswerCorrect; then thenBlock else elseBlock"></span>
                        <ng-template #thenBlock>
                            <div class="resultRow" style="color:green">
                                <span class="resultCol1">ID {{ question.id }}</span>
                                <span class="resultCol2">Answer was: CORRECT.</span>
                                <span class="resultCol3">Correct answer: {{ question.correctAnswers }}</span>
                                <span class="resultCol4">Your answer: {{ question.selectedAnswers }}</span>
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            <div class="resultRow" style="color:red">
                                <span class="resultCol1">ID {{ question.id }}</span>
                                <span class="resultCol2">Answer was: WRONG.</span>
                                <span class="resultCol3">Correct answer: {{ question.correctAnswers }}</span>
                                <span class="resultCol4">Your answer: {{ question.selectedAnswers }}</span>
                            </div>
                        </ng-template>
                    </div>
                    <div class="questionButtons">
                        <button type="button" mat-raised-button (click)="showQuiz()">Back to Quiz</button>
                    </div>
                </div>
            </section>
        </ng-template>
    </div>
</div>

<!-- Footer -->
<app-footer></app-footer>