import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { QuestionsComponent } from './questions/questions.component';
import { FooterComponent } from './footer/footer.component';
import { QuestionService } from './questions/service/question.service';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';

import { HttpClientModule } from '@angular/common/http';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

// Authentication
import { AuthService } from './login/service/auth.service';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JsonService } from './questions/service/json.service';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    QuestionsComponent,
    FooterComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule
  ],
  providers: [
    QuestionService,
    AuthService,
    JsonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
