import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionModal } from 'src/app/shared/question.modal';
import { JsonService } from "./json.service";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private jsonService: JsonService) { }

  getAllQuestions() {
    return this.jsonService.getData("../../assets/data/questions.json");
  }
}
