import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  	userData: any;

	constructor(
		private afAuth: AngularFireAuth,
		private router: Router) {
			/* Saving user data in localstorage when logged in and setting up null when logged out */
			this.afAuth.authState.subscribe(user => {
				if (user) {
				this.userData = user;
				localStorage.setItem('user', JSON.stringify(this.userData));
				JSON.parse(localStorage.getItem('user') || '{}');
				this.router.navigate(['/questions']);
				} else {
				localStorage.removeItem('user');
				JSON.parse(localStorage.getItem('user') || '{}');
				this.router.navigate(['/login']);
				}
			})
		}

	// Login
	login(email:string, password:string) {
		return this.afAuth.signInWithEmailAndPassword(email, password);
	}

	// Logout
	logout() {
		return this.afAuth.signOut();
	}

	// Returns true when user is looged in and email exists
	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem('user') || '{}');
		const isLoggedIn =  (Object.entries(user).length !== 0 && user.hasOwnProperty('email') && user.email !== null) ? true : false;
		return isLoggedIn;
	}
}
