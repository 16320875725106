<app-toolbar></app-toolbar>

<div class="main">
    <div class="box">
        <mat-card>
            <mat-card-title>Login</mat-card-title>
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <p>
                        <mat-form-field>
                            <input type="text" matInput placeholder="Username" formControlName="username">
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field>
                            <input type="password" matInput placeholder="Password" formControlName="password">
                        </mat-form-field>
                    </p>

                    <p *ngIf="errorMessage" class="error">
                        {{ errorMessage }}
                    </p>

                    <div class="button">
                        <button type="submit" mat-button>Login</button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Footer -->
<app-footer></app-footer>