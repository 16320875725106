import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './service/auth.service';


@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean { 
        if(this.authService.isLoggedIn !== true) {
            console.log("Redirecting to login!");
            this.router.navigate(['/login'])
            return false;
        }
        
        return true;
    }
}