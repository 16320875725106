import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QuestionModal } from 'src/app/shared/question.modal';

@Injectable()
export class JsonService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  } 

  constructor(private http: HttpClient) { }
    getData(url: string): Observable<any> {
        return this.http.get<QuestionModal>(url);
    }

}